/*
* komponen ini menggunakan material tailwind, jika ingin mengubah atau menambah
* lihat halaman ini sebagai referensi
* https://www.material-tailwind.com/docs/react/installation
*
*/

import { RootContext } from '../../App';
import axiosInstance from '../../config/axios';
import refactorStructureData from '../../helpers/refactorDataMenu';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Button from "@material-tailwind/react/Button";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";

export default function RolingTier() {

    const [listData, setListData] = useState();
    const [dataMin, setDataMin] = useState();
    const [dataMax, setDataMax] = useState();
    const [dataId, setDataId] = useState();
    const { state, setState } = useContext(RootContext);
    const history = useHistory();
    const [saving, setSaving] = useState();

    const group = JSON.parse(localStorage.getItem("group"));

    const handleSave = () => {

        const token = localStorage.getItem("access_token");
        const data = {

            token: token,
            group: group.wbid,
            tierid: dataId,
            tiermin: dataMin,
            tiermax: dataMax,
        }

        if (!token) {

            history.push("/login");

        } else {

            try {

                axiosInstance('update_roling_tier', {

                    method: "POST",

                    data,

                }).then((res) => {

                    if (res.data.code === 0) {

                        Swal.fire(
                            'Saved',
                            'Data Saved',
                            'success',
                        ).then(() => {

                            setListData(res.data.data.list);
                            setSaving(false);
                        })

                    } else {

                        Swal.fire(
                            'Failed',
                            'Failed To save',
                            'error',
                        );

                        setSaving(false);
                        console.log('Error Mesage: ' + res.data.message);
                    }

                }).catch((err) => {

                    let errstatus = err.response.status;

                    let errmesage = err.response.data.message;

                    if (errstatus === 401) {

                        Swal.fire(
                            'Expire Token',
                            'Your Token Has Expire',
                            'warning'
                        ).then(() => {

                            localStorage.removeItem("access_token");

                            history.push("/login");
                        });

                    } else {

                        Swal.fire(
                            'Warning',
                            errmesage,
                            'warning'
                        ).then(() => {

                            setSaving(false);

                        })
                    }

                });

            } catch (error) {

                Swal.fire(
                    'Warning',
                    error,
                    'warning'
                ).then(() => {

                    setSaving(false);

                })

            }

        }
    }

    const getData = () => {

        const token = localStorage.getItem("access_token");

        const data = {

            token: token,
            group: group.wbid,

        }

        if (!token) {

            history.push("/login");
            console.log('Not Valid Token');

        } else {

            try {

                axiosInstance('get_roling_tier', {

                    method: "POST",

                    data,

                }).then((res) => {

                    if (res.data.code === 0) {

                        const data = res.data.data.list;

                        setListData(data);

                        setState({

                            ...state,

                            group: res.data.data.additional_data.group,

                            menu: refactorStructureData(res.data.data.additional_data.menu),

                        });
                    } else {

                        Swal.fire(
                            'Internal Server Error',
                            "Please Contact Developer",
                            'error'
                        )
                    }

                }).catch((err) => {

                    let errstatus = err.response.status;

                    let errmesage = err.response.data.message;

                    if (errstatus === 401) {

                        Swal.fire(
                            'Expire Token',
                            'Your Token Has Expire',
                            'warning'
                        ).then(() => {

                            localStorage.removeItem("access_token");

                            history.push("/login");
                        });

                    } else {

                        Swal.fire(
                            'Warning',
                            errmesage,
                            'warning'
                        ).then(() => {

                            setSaving(false);

                        })
                    }

                });

            } catch (error) {

                Swal.fire(
                    'Warning',
                    error,
                    'warning'
                ).then(() => {

                    setSaving(false);

                })

            }

        }

    };

    useEffect(() => {

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!listData ? (

                <div className="flex justify-center" style={{ marginTop: 150 }}>

                    <ReactLoading
                        type="spinningBubbles"
                        color="rgb(53, 126, 221)"
                        height={100}
                        width={100}
                    />

                </div>

            ) : (

                <Card>

                    <CardHeader color="blueGray" contentPosition="none">

                        <div className="w-full flex items-center justify-between">

                            <h2 className="text-white text-2xl">Rolling Tier</h2>

                        </div>

                    </CardHeader>

                    <CardBody>

                        {listData.map(item => (

                            <div key={item.id} >

                                <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                                    {item.name}

                                </h6>

                                <div className="flex flex-wrap mt-10 font-light">

                                    <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                                        <Input
                                            color="purple"
                                            placeholder="Min Turnover"
                                            defaultValue={item['min']}
                                            onChange={(e) => {

                                                setDataMin(e.target.value);
                                                setDataId(item.id);
                                            }}
                                        />

                                    </div>

                                    <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                                        <Input
                                            color="purple"
                                            placeholder="Max Turnover"
                                            defaultValue={item['max']}
                                            onChange={(e) => {

                                                setDataMax(e.target.value);
                                                setDataId(item.id);
                                            }}
                                        />
                                    </div>

                                </div>

                                <div className="w-full flex items-center justify-between">

                                    <Button
                                        className="h-10
                                    px-5 m-2
                                    text-green-100 
                                    transition-colors 
                                    duration-150 
                                    bg-green-700 
                                    rounded-lg 
                                    focus:shadow-outline 
                                    hover:bg-green-800"
                                        onClick={() => {

                                            setSaving(true);

                                            handleSave();

                                        }}

                                    >
                                        {!saving ? 'Save' : 'Saving'}

                                    </Button>

                                </div>
                            </div>
                        ))}
                    </CardBody>
                </Card>
            )}

        </>

    );

}
