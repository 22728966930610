import React from 'react'
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from 'react-data-table-component';
import InputIcon from "@material-tailwind/react/InputIcon";
import { Button } from "@material-tailwind/react";

function TablePlayerReferralDetail({ 
    detailName, fromDate, toDate, playerReferral, pending, usernameEvent, fromCreateAtEvent, toCreateAtEvent, onChangeRowsPerPage, MinimalWLEvent, MaximalWLEvent, MinimalDepositEvent, MaximalDepositEvent}) {

    const username_login = localStorage.getItem("username")
    const columns = [
        {
            name: 'Name',
            selector: (row, idx) => row.name,
            sortable: false,

        },
        {
            name: 'Deposit Times',
            selector: (row) => row.total_depo,
            sortable: false,

        },
        {
            name: 'Turnover',
            selector: (row) => row.turnover,
            sortable: true,

        },
        {
            name: 'Winlose',
            selector: (row) => row.winlose,
            sortable: true,

        },
        {
            name: 'Bank Account',
            selector: (row) => row.bank_acc,
            sortable: true,

        },
        {
            name: 'Balance',
            selector: (row) => row.balance,
            sortable: false,

        },
        {
            name: 'Bank Name',
            selector: (row) => row.bank_name,
            sortable: false,
        },
        {
            name: 'Phone',
            selector: (row) => (username_login.toLowerCase() === 'master' || username_login.toLowerCase() === 'aakaudit') ? row.phone : '?????????',
            sortable: false,

        },
        {
            name: 'Bank Number',
            selector: (row) => (username_login.toLowerCase() === 'master' || username_login.toLowerCase() === 'aakaudit') ? row.bank_num : '?????????',
            sortable: false,
        },
        {
            name: 'Last IP',
            selector: (row) => (username_login.toLowerCase() === 'master' || username_login.toLowerCase() === 'aakaudit') ? row.ip_current : '?????????',
            sortable: true,
        },
    ];

    const handleExport = (dataJson) => {
        if(username_login.toLowerCase() !== 'master' && username_login.toLowerCase() !== 'aakaudit' && username_login.toLowerCase() !== 'aakwiliam') return false;
        const header = 'Name,Deposit Times,Turnover,Winlose,Phone,Last IP,Balance,Bank Account,Bank Number\n';
        const csvData = dataJson.map(d => `"${d.name}","${d.total_depo}","${d.turnover}","${d.winlose}","${d.phone}","${d.ip_current},"${d.balance}","${d.bank_acc}","${d.bank_num}""\n`).join('');
        const blob = new Blob([header + csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', detailName +'-'+fromDate + '-' + toDate + '.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (
        <div>
            <div>
                <Card>
                <h5>Advanced Search</h5>
                <CardBody>
                    <div className="flex gap-4">
                    <InputIcon
                        type="text"
                        color="lightBlue"
                        size="sm"
                        outline={true}
                        placeholder="Downline Name"
                        iconName="search"
                        onChange={usernameEvent}
                    />
                    </div>
                    <div className="flex mt-4">
                        <div style={{ width: '100%', display: 'flex', gap: '10px', flexDirection: "column", border: '1px solid #DCDCDC', borderRadius: '10px', padding: '20px' }}>
                            <p>Date Filter</p>
                            <InputIcon
                            type="date"
                            color="lightBlue"
                            size="sm"
                            outline={true}
                            placeholder="From"
                            iconName=""
                            onChange={fromCreateAtEvent}
                            />
                            <InputIcon
                            type="date"
                            color="lightBlue"
                            size="sm"
                            outline={true}
                            placeholder="To"
                            iconName=""
                            onChange={toCreateAtEvent}
                            />
                        </div>
                    </div>
                    <div className="flex mt-4">
                        <div style={{ width: '100%', display: 'flex', gap: '10px', flexDirection: "column", border: '1px solid #DCDCDC', borderRadius: '10px', padding: '20px', marginRight: '10px' }}>
                            <p>Win Lose</p>
                            <InputIcon
                                type="text"
                                color="lightBlue"
                                size="sm"
                                outline={true}
                                placeholder="Minimal Win Lose"
                                iconName=""
                                onChange={MinimalWLEvent}
                            />
                            <InputIcon
                                type="text"
                                color="lightBlue"
                                size="sm"
                                outline={true}
                                placeholder="Maximal Win Lose"
                                iconName=""
                                onChange={MaximalWLEvent}
                            />
                        </div>
                        <div style={{ width: '100%', display: 'flex', gap: '10px', flexDirection: "column", border: '1px solid #DCDCDC', borderRadius: '10px', padding: '20px', marginLeft: '10px'}}>
                            <p>Deposit Times</p>
                            <InputIcon
                                type="text"
                                color="lightBlue"
                                size="sm"
                                outline={true}
                                placeholder="Minimal Deposit Times"
                                iconName=""
                                onChange={MinimalDepositEvent}
                            />
                            <InputIcon
                                type="text"
                                color="lightBlue"
                                size="sm"
                                outline={true}
                                placeholder="Maximal Deposit Times"
                                iconName=""
                                onChange={MaximalDepositEvent}
                            />
                        </div>
                    </div>
                </CardBody>
                </Card>
                <Card className="mt-12">
                <CardHeader color="blueGray" contentPosition="left">
                    <h2 className="text-white text-2xl">Detail Referral {detailName}</h2>
                        <p>{fromDate} ~ {toDate}</p>
                </CardHeader>
                <CardBody >
                    <Button onClick={() => handleExport(playerReferral)}>Export To CSV</Button>
                    <DataTable
                    columns={columns}
                    data={playerReferral}
                    progressPending={pending}
                    //onChangeRowsPerPage={onChangeRowsPerPage}
                    //paginationRowsPerPageOptions={[10, 50, 100, 500]}
                    //pagination
                    />
                </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default TablePlayerReferralDetail